import Phaser from 'phaser';
import PlayTable from "../play_table/PlayTable";
import Client from "../../../client/Client";
import Button from "../../../shared/Button";
import RulesSelector from "./RulesSelector";

const NUM_PLAYERS = 8;

class ChooseSeat extends Phaser.Scene {

    init(data) {
        this.client = data.client;
        this.clientName = data.clientName;
        this.leoMode = data.leoMode;
        this.seatSelected = this.seatSelected.bind(this);
        this.pendingSit = false;
        this.seatRotationOffset = 0;
        this.weAreDoneHere = false;
    }

    create() {
        if (!this.client) {
            this.client = new Client();
            this.scene.launch("ConnectionStatus", { client: this.client });
        }
        this.seats = [];
        for (let i = 0; i < NUM_PLAYERS; ++i) {
            this.seats[i] = new Seat(this, 0, 0, i, this.seatSelected);
        }
        this.nullSeat = new Seat(this, 0, 0, 999, this.seatSelected);
        this.centerText = this.add.text(0, 0, "Please take a seat", {font: "24px Tahoma"});
        this.centerText.setOrigin(0.5, 0.5);
        this.startButton = new Button(this, {
            onPressed: this.startPressed.bind(this), unpressed: 'card-button', text: "START", scale: 0.5});

        this.scene.remove("PlayTable");
        this.scene.add("PlayTable", PlayTable);
    }

    onRuleChange(id, value) {
        this.client.emit({ event: "SET_RULE", id, value });
    }

    seatSelected(seatNumber) {
        this.client.emit({event: "REQUEST_SEAT", seatNumber: seatNumber, name: this.clientName});
        if (seatNumber <= this.seats.length - 1) {
            this.pendingSit = true;
            this.seats.forEach(seat => {
                seat.setPendingSit(true);
            })
        }
    }

    startPressed() {
        this.client.emit({event: "START_GAME", name: this.clientName});
    }

    seatAccepted() {
        this.pendingSit = false;
        this.seats.forEach(seat => { seat.setPendingSit(false); })
    }

    update() {
        this.scene.setVisible(this.client.isConnected());
        this.updatePositions();
        this.checkEvents();
    }

    checkEvents() {
        const replaceEvents = [];
        while (true) {
            const event = this.client.consumeEvent();
            if (!event) break;
            switch (event.event) {
                case 'LOBBY_SYNC':
                    if (!this.weAreDoneHere && !this.rulesetLoaded) {
                        this.rulesSelector = new RulesSelector(this, this.onRuleChange.bind(this));
                        for (const ruleId in event.rules) {
                            this.rulesSelector.addRule(ruleId, event.rules[ruleId].name, event.rules[ruleId].options);
                        }
                        this.rulesSelector.setCanModify(false);
                        this.rulesetLoaded = true;
                    }
                    this.seats.forEach(seat => { seat.emptySeat(true); });
                    event.seats.forEach((name, index) => {
                        if (!name) return;
                        this.seats[index].fillSeat(name);
                        this.seatAccepted();
                    })
                    this.rulesSelector && this.rulesSelector.setCanModify(this.seats[0].getSeatedName() === this.clientName);
                    for (const ruleId in event.rules) {
                        this.rulesSelector.setRuleValue(ruleId, event.rules[ruleId].value);
                    }
                    break;
                case 'GAME_SYNC':
                    this.goToTable();
                    replaceEvents.push(event);
                    break;
                default:
                    replaceEvents.push(event);
            }
        }

        this.client.addEvents(replaceEvents);
    }

    goToTable() {
        if (!this.weAreDoneHere) {
            this.scene.launch("PlayTable", {client: this.client, clientName: this.clientName, leoMode: this.leoMode});
            this.scene.bringToTop("ConnectionStatus");
            this.scene.stop();
            this.rulesetLoaded = false;
            this.weAreDoneHere = true;
        }
    }

    updatePositions() {
        const gameSize = { width: this.scale.width, height: this.scale.height };
        const seatCenter = { x: gameSize.width / 2, y: -120 + gameSize.height / 2 };
        this.centerText.setPosition(seatCenter.x, seatCenter.y);

        if (this.pendingSit) {
            this.seatRotationOffset += 0.03;
        } else {
            this.seatRotationOffset = 0;
        }

        const distanceOffset = (10 * Math.sin(this.seatRotationOffset));
        for (let i = 0; i < NUM_PLAYERS; ++i) {
            const xOffset = (175 + distanceOffset) * Math.sin(((Math.PI*2) / NUM_PLAYERS) * i);
            const yOffset = (-175 + distanceOffset) * Math.cos(((Math.PI*2) / NUM_PLAYERS) * i);
            this.seats[i].setPosition(seatCenter.x + xOffset, seatCenter.y + yOffset);
        }
        this.nullSeat.setPosition(gameSize.width - 60, gameSize.height - 60);

        this.startButton.setPosition(seatCenter.x, seatCenter.y + 330);

        this.rulesSelector && this.rulesSelector.setPosition(20, gameSize.height - (this.rulesSelector.getNumberOfRules() * 32));
    }

}

class Seat {
    constructor(scene, x, y, seatNumber, onSeatSelected) {
        this.filled = false;

        this.textures = { empty: 'seat', filled: 'seat-filled' };
        this.sprite = scene.add.sprite(x, y, this.textures.empty).setInteractive();

        this.text = scene.add.text(0, 0, "Sit here");
        this.text.setOrigin(0.5, 0.5);
        this.text.setVisible(false);

        const that = this;
        this.sprite.on(Phaser.Input.Events.POINTER_OVER, function () {
            if (!that.filled) that.text.setVisible(true);
        });
        this.sprite.on(Phaser.Input.Events.POINTER_OUT, function () {
            if (!that.filled) that.text.setVisible(false);
        });
        this.sprite.on(Phaser.Input.Events.POINTER_DOWN, function () {
            onSeatSelected(seatNumber);
        });
    }

    setPosition(x, y) {
        this.sprite.setPosition(x, y);
        this.text.setPosition(x, y);
    }

    fillSeat(name) {
        this.filled = true;
        this.sprite.setTexture(this.textures.filled);
        this.text.setVisible(true);
        this.text.setText(name);
    }

    getSeatedName() {
        return this.filled ? this.text.text : "";
    }

    emptySeat() {
        this.filled = false;
        this.sprite.setTexture(this.textures.empty);
        this.text.setVisible(false);
        this.text.setText("Sit here");
    }

    setPendingSit(set) {
        set ? this.sprite.removeInteractive() : this.sprite.setInteractive();
        if (set) this.text.setVisible(false);
    }
}

export default ChooseSeat;