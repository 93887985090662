import {BASE_CARD_DEPTH} from "./scenes/play_table/PlayTable";
import PlacementTextMap from "./scenes/play_table/PlacementTextMap";

class Hand {
    constructor(scene, onCardSelected) {
        this.scene = scene;
        this.background = scene.add.sprite(0, 0, 'hand-background');
        this.background.setOrigin(0.5, 0);
        this.background.setDepth(-1);
        this.background.displayHeight = 199;
        this.winWreath = scene.add.sprite(0, 0, 'flower-circle');
        this.winWreath.setVisible(false);
        this.placementText = scene.add.text(0, 0, "", {font: "24px Tahoma"});
        this.placementText.setVisible(false);
        this.placementText.setOrigin(0.5, 0.5);
        this.cards = [];
        this.scene.scale.on('resize', this.positionOnResize, this);
        this.onCardSelected = onCardSelected;
        this.numIncomingCards = 0;
    }

    showAwardWreath(placement) {
        this.placementText.setVisible(true);
        this.placementText.setText(PlacementTextMap[placement]);
        this.winWreath.setTexture(placement === 1 ? 'flower-circle' : 'flower-circle-2');
        this.winWreath.setScale(0);
        this.winWreath.setVisible(true);
        this.scene.tweens.add({
            targets: [this.winWreath],
            scaleX: 1,
            scaleY: 1,
            duration: 2000,
            ease: 'Bounce.easeOut'
        });
    }

    update() {
        const gameSize = { width: this.scene.scale.width, height: this.scene.scale.height };
        this.background.setPosition(gameSize.width/2, gameSize.height - this.background.displayHeight);
        if (this.winWreath.visible) {
            this.winWreath.setPosition(gameSize.width / 2, gameSize.height - 50 -this.background.displayHeight / 2);
            this.placementText.setPosition(gameSize.width / 2, gameSize.height - 50 - this.background.displayHeight / 2);
        }
        this.background.setVisible(this.cards.length > 0);
    }

    indicateValidCards(cards) {
        this.cards.forEach((card) => {
            card.setValidIndication(cards.includes(card.value));
        });
    }

    setIncomingCards(numIncomingCards) {
        this.numIncomingCards = numIncomingCards;
    }

    setCards(cards) {
        this.cards.forEach(card => { card.destroy(); })
        this.cards = cards;
        this.positionCards(false);
        this.makeCardsSelectable();
    }

    addCard(card) {
        this.cards.push(card);
        this.positionOneCard(this.cards.length - 1);
        this.makeCardsSelectable();
    }

    removeCard(index) {
        const card = this.cards.splice(index, 1)[0];
        card.setSelectable(false);
        card.setValidIndication(false);
        return card;
    }

    makeCardsSelectable() {
        const that = this;
        this.cards.forEach((card, index) => {
            card.setSelectable(true);
            card.setSelectionCallback((color) => { that.onCardSelected(index, color);});
        });
    }

    positionOnResize() {
        this.positionCards(false);
    }

    calculateCardPositionInfo() {
        const totalCards = this.cards.length + this.numIncomingCards;
        const cardGap = Math.max(Math.min(1000 / totalCards, 125), 21);
        const handWidth = (totalCards-1) * (cardGap);
        const start = { x: this.scene.scale.width/2 - handWidth/2, y: (this.scene.scale.height - this.background.displayHeight/2) + 14 };

        return { cardGap, start };
    }

    positionCards(tween=true) {
        if (this.cards.length === 0) return;
        const { cardGap, start } = this.calculateCardPositionInfo();
        this.cards.forEach((card, index) => {
            this.doCardPositioning(card, cardGap, start, index, tween);
        });
        this.orderCards();
    }

    positionOneCard(index) {
        const { cardGap, start } = this.calculateCardPositionInfo();
        const card = this.cards[index];
        this.doCardPositioning(card, cardGap, start, index, true);
    }

    doCardPositioning(card, cardGap, start, index, tween=true) {
        const x = start.x + (index*cardGap);
        const y = start.y;
        const scaleX = 0.75;
        const scaleY = 0.75;
        if (tween) {
            card.tween({ x, y, ease: 'Cubic.easeInOut', duration: 1800 });
            card.tween({ scaleX, scaleY, ease: 'Cubic.easeInOut', duration: 500, angle: 0 });
            card.faceUp(true, true, 500);
            this.scene.time.delayedCall(750, () => { card.setDepth(BASE_CARD_DEPTH + index); });
        } else {
            card.setPosition(x, y);
            card.setScale(scaleX);
            card.setAngle(0);
            card.setDepth(BASE_CARD_DEPTH + index);
        }
    }

    orderCards() {
        this.cards.forEach((card, index) => { card.setDepth(BASE_CARD_DEPTH + index) });
    }

    destroy() {
        this.scene.scale.off('resize', this.positionOnResize, this);
    }
}

export default Hand;