import Phaser from "phaser";

function MakeSpriteButton(sprite, { onHover, onPressed }) {
    sprite.on(Phaser.Input.Events.POINTER_OVER, function () {
        if (onHover) onHover(true);
        sprite.over = true;
    });
    sprite.on(Phaser.Input.Events.POINTER_OUT, function () {
        if (onHover) onHover(false);
        sprite.over = false;
        sprite.buttonHeld = false;
    });
    sprite.on(Phaser.Input.Events.POINTER_DOWN, function () {
        if (sprite.over) sprite.buttonHeld = true;
    });
    sprite.on(Phaser.Input.Events.POINTER_UP, function () {
        if (sprite.over && sprite.buttonHeld) onPressed();
        sprite.buttonHeld = false;
    });
}

export default MakeSpriteButton;