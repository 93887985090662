const map = {
    "RED_0": "9",
    "RED_1": "0",
    "RED_2": "1",
    "RED_3": "2",
    "RED_4": "3",
    "RED_5": "4",
    "RED_6": "5",
    "RED_7": "6",
    "RED_8": "7",
    "RED_9": "8",
    "RED_DRAW_2": "42",
    "RED_REVERSE": "41",
    "RED_SKIP": "40",
    "YELLOW_0": "19",
    "YELLOW_1": "10",
    "YELLOW_2": "11",
    "YELLOW_3": "12",
    "YELLOW_4": "13",
    "YELLOW_5": "14",
    "YELLOW_6": "15",
    "YELLOW_7": "16",
    "YELLOW_8": "17",
    "YELLOW_9": "18",
    "YELLOW_DRAW_2": "45",
    "YELLOW_REVERSE": "44",
    "YELLOW_SKIP": "43",
    "BLUE_0": "39",
    "BLUE_1": "30",
    "BLUE_2": "31",
    "BLUE_3": "32",
    "BLUE_4": "33",
    "BLUE_5": "34",
    "BLUE_6": "35",
    "BLUE_7": "36",
    "BLUE_8": "37",
    "BLUE_9": "38",
    "BLUE_DRAW_2": "51",
    "BLUE_REVERSE": "50",
    "BLUE_SKIP": "49",
    "GREEN_0": "29",
    "GREEN_1": "20",
    "GREEN_2": "21",
    "GREEN_3": "22",
    "GREEN_4": "23",
    "GREEN_5": "24",
    "GREEN_6": "25",
    "GREEN_7": "26",
    "GREEN_8": "27",
    "GREEN_9": "28",
    "GREEN_DRAW_2": "48",
    "GREEN_REVERSE": "47",
    "GREEN_SKIP": "46",
    "WILD": "52",
    "WILD_DRAW_4": "54"
}

export default map;