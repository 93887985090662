// Outer number = no. of opponents
// Inner array, positions of opponents (distance from center)
// Values are percentages from that edge, e.g. left: 30 means place it 30% across the screen from the left

export default {
    '1': [{ left: 50, top: 15 }],
    '2': [{ left: 25, top: 40 }, { right: 25, top: 40 }],
    '3': [{ left: 25, top: 40 }, { left: 50, top: 15}, { right: 25, top: 40 }],
    '4': [{ left: 15, top: 50 }, { left: 35, top: 25 }, { right: 35, top: 25}, { right: 15, top: 50}],
    '5': [{ left: 10, top: 50 }, { left: 30, top: 25 },{ left: 50, top: 15 }, { right: 30, top: 25}, { right: 10, top: 50}],
    '6': [{ left: 10, bottom: 55 }, { left: 20, top: 15 }, { left: 40, top: 15 }, { right: 40, top: 15 }, { right: 20, top: 15 }, { right: 10, bottom: 55 }],
    '7': [{ left: 10, bottom: 50 }, { left: 10, top: 15 }, { left: 30, top: 15 }, { left: 50, top: 15 }, { right: 30, top: 15 }, { right: 10, top: 15 }, { right: 10, bottom: 50 }],
    '8': [{ left: 50, bottom: 20 }, { left: 10, bottom: 50 }, { left: 10, top: 15 }, { left: 30, top: 15 }, { left: 50, top: 15 }, { right: 30, top: 15 }, { right: 10, top: 15 }, { right: 10, bottom: 50 }],
}