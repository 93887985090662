import Card from "../../Card";

class Deck {
    constructor(scene, onClick) {
        this.scene = scene;
        this.deck = [];
        this.position = { x: 0, y: 0 };
        this.scene.scale.on('resize', this.autoPosition, this);
        this.autoPosition();
        this.onClick = onClick;
    }

    popCard() {
        const card = this.deck.pop();
        card.setSelectable(false, true);
        this.makeTopCardSelectable();
        return card;
    }

    makeTopCardSelectable() {
        const card = this.deck[this.deck.length - 1];
        if (card) {
            card.setSelectable(true, true);
            card.setSelectionCallback(this.onClick);
        }
    }

    setNumCards(num, colorPicker) {
        this.deck.forEach(card => card.destroy());
        this.deck = [];
        for (let i = 0; i < num; ++i) {
            const card = new Card(this.scene, 0, 0, 'uno-deck', null, colorPicker);
            card.faceUp(false, false);
            card.setAngle(10);
            this.deck.push(card);
        }
        this.makeTopCardSelectable();
        this.autoPosition();
    }

    autoPosition() {
        const gameSize = { width: this.scene.scale.width, height: this.scene.scale.height };
        this.position = { x: 160 + gameSize.width / 2, y: 50 + gameSize.height / 2 };
        this.deck.forEach((card, i) => {
            card.setPosition(this.position.x, this.position.y-(i * 0.35));
        });
    }

    destroy() {
        this.scene.scale.off('resize', this.autoPosition, this);
    }
}

export default Deck;