import {DISCARD_BASE_CARD_DEPTH} from "./PlayTable";

class Discard {
    constructor(scene) {
        this.scene = scene;
        this.cards = [];
        this.scene.scale.on('resize', this.autoPosition, this);
        this.autoPosition();
    }

    addCard(card, tween=true, { fromHand=false, fromOpponent=false }={}) {
        this.cards.push(card);
        if (this.cards.length > 50) {
            this.removedCards = this.cards.splice(0, 25);
            this.removedCards.forEach(card => { card.destroy(); });
            this.orderCards();
        }
        if (tween) {
            let ease = 'Cubic.easeInOut';
            let scaleDuration = 500;
            let moveDelay = 0;
            let moveDuration = 1800;
            let angleDuration = 500;
            if (fromHand) {
                ease = 'Cubic.easeOut';
                scaleDuration = 500;
                moveDelay = 0;
                moveDuration = 1500;
                angleDuration = moveDuration;
            } else if (fromOpponent) {
                ease = 'Cubic.easeOut';
                scaleDuration = 250;
                moveDelay = 1250;
                moveDuration = 1000;
                angleDuration = moveDuration;
            }
            card.tween({scaleX: 0.85, scaleY: 0.85, duration: scaleDuration});
            card.tween({angle: -30 + Math.random() * 60, duration: angleDuration, delay: moveDelay });
            card.tween({x: this.position.x, y: this.position.y, ease, duration: moveDuration, delay: moveDelay});
            card.faceUp(true, true, scaleDuration);
        } else {
            card.setPosition(this.position.x, this.position.y);
            card.setAngle(-30 + Math.random() * 60);
            card.setScale(0.85, 0.85);
            card.faceUp(true, false);
        }
        card.setDepth(DISCARD_BASE_CARD_DEPTH + this.cards.length - 1);
    }

    autoPosition() {
        const gameSize = { width: this.scene.scale.width, height: this.scene.scale.height };
        this.position = { x: gameSize.width / 2, y: -35 + gameSize.height / 2 };
        this.cards.forEach((card, i) => {
            card.setPosition(this.position.x, this.position.y);
            card.setScale(0.85);
        });
        this.orderCards();
    }

    orderCards() {
        this.cards.forEach((card, index) => { card.setDepth(DISCARD_BASE_CARD_DEPTH + index) });
    }

    destroy() {
        this.scene.scale.off('resize', this.autoPosition, this);
    }
}

export default Discard;