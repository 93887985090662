import Phaser from 'phaser';
import { IonPhaser } from '@ion-phaser/react'
import React from "react";
import ChooseSeat from "./scenes/choose_seat/ChooseSeat";
import ConnectionStatus from "./scenes/connection_status/ConnectionStatus";

function create() {
    this.scene.add("ChooseSeat", ChooseSeat);
    this.scene.add("ConnectionStatus", ConnectionStatus);

    this.scene.launch("ChooseSeat", { clientName: this.clientName, leoMode: this.leoMode });
    this.scene.stop();
    this.scene.destroy();
}

function GameContainer({ clientName, leoMode=false }) {
    const [game, setGame] = React.useState(null);
    React.useEffect(() => {
        const taggedName = clientName + getTag(clientName.toLowerCase(), [
            { keys: ["tog", "tolg"], tag: "Elve" },
            { keys: ["keb", "ken", "turk"], tag: "Rat" },
            { keys: ["joe", "josh", "blath", "blaf", "virus"], tag: "Igbian" },
            { keys: ["koront"], tag: "Milk" },
            { keys: ["leo", "obi", "aking"], tag: "Monkey" },
            { keys: ["batman", "hamed", "waveyhamz"], tag: "When is jollof sesh" },
        ]);

        setGame({
            parent: 'game-parent',
            type: Phaser.AUTO,
            backgroundColor: '#00760B',
            scene: { preload, create, extend: { clientName: taggedName, leoMode } },
            scale: {
                mode: Phaser.Scale.RESIZE
            }
        });
    }, []);

    if (!game) return null;
    return <div id={"game-parent"} style={{width: '100vw', height: '100vh', minWidth: '1200px', minHeight: '700px', overflow: 'none'}}>
        <IonPhaser game={game}/>
    </div>;
}

function getTag(name, map) {
    for (let i = 0; i < map.length; ++i) {
        for (let k = 0; k < map[i].keys.length; ++k) {
            if (name.includes(map[i].keys[k])) return ` (${map[i].tag})`;
        }
    }
    return "";
}

function preload() {
    const gameSize = { width: this.scale.width, height: this.scale.height };
    const loadBarSize = { x: 320, y: 50 }
    const progressBox = this.add.graphics();
    const progressBar = this.add.graphics();
    progressBox.fillStyle(0xFF0000, 1);
    progressBox.fillRect(gameSize.width/2 - loadBarSize.x/2, gameSize.height/2 - loadBarSize.y/2, loadBarSize.x, loadBarSize.y);
    this.load.on('progress', function (value) {
        progressBar.clear();
        progressBar.fillStyle(0xFFFF00, 1);
        progressBar.fillRect(10 + (gameSize.width/2 - loadBarSize.x/2), 10 +(gameSize.height/2 - loadBarSize.y/2), value * (loadBarSize.x - 20), loadBarSize.y - 20);
    });

    this.load.on('complete', function () {
        progressBar.destroy();
        progressBox.destroy();
    });

    this.load.path = "game_assets/uno/";
    // Lobby
    this.load.image("seat", "seat.png");
    this.load.image("seat-filled", "seat_filled.png");
    this.load.image("card-button", "card_back.png");
    // Game
    this.load.image("uno-deck", "uno-deck.png");
    this.load.image("uno-back", "uno-back.png");
    this.load.image("turn-sun", "turn_sun.png");
    this.load.image("round-unpressed", "round_unpressed.png");
    this.load.image("round-pressed", "round_pressed.png");
    this.load.image("color-picker", "color_picker.png");
    this.load.image("wedge-red", "wedge_red.png");
    this.load.image("wedge-blue", "wedge_blue.png");
    this.load.image("wedge-yellow", "wedge_yellow.png");
    this.load.image("wedge-green", "wedge_green.png");
    this.load.image("black-pixel", "black_pixel.png");
    this.load.image("green-background", "green_background.png");
    this.load.image("hand-background", "hand_background.png");
    this.load.image("arrows", "arrows.png");
    this.load.image("flower-circle", "flower_circle.png");
    this.load.image("flower-circle-2", "flower_circle_2.png");
    this.load.image("leo-unpressed", "leo.png");
    this.load.image("leo-pressed", "leo2.png");

    // Sound
    this.load.audio("ding-dong", "sound/ding-dong.ogg");
    this.load.audio("leo-button", "sound/leo-button.ogg");
}

export default GameContainer;