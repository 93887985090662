import Phaser from "phaser";

class ConnectionStatus extends Phaser.Scene {

    init(data) {
        this.client = data.client;

        // Set this initially wrong so it's corrected in update()
        this.connected = !this.client.isConnected();
    }

    create() {
        this.text = this.add.text(5, 5, "...", { color: 'white' });
    }

    update() {
        if (this.connected && !this.client.isConnected()) {
            this.text.setText("Server is warming up...this shouldn't take more than a minute or so...");
            this.connected = false;
        } else if (!this.connected && this.client.isConnected()) {
            this.text.setText("Connected :-)");
            this.connected = true;
            this.sound.play("ding-dong");
        }
    }

}

export default ConnectionStatus;