import Phaser from "phaser";

class RulesSelector {
    constructor(scene, onChange) {
        this.scene = scene;
        this.container = scene.add.container();
        this.setPosition = this.container.setPosition.bind(this.container);
        this.onChange = onChange;
        this.rules = {};
    }

    onRuleChange(id, value) {
        if (this.canModifyRules) {
            this.onChange(id, value);
        }
    }

    getNumberOfRules() {
        return Object.keys(this.rules).length;
    }

    setCanModify(set) {
        this.canModifyRules = set;
        this.container.list.forEach(text => {
            text.setTint(set ? 0xFFFFFF : 0x333333);
            if (set) text.setInteractive();
            else text.disableInteractive();
        });
    }

    setRuleValue(id, value) {
        if (this.rules[id]) this.rules[id].setValue(value);
    }

    addRule(id, name, options) {
        const rule = new Rule(this.scene, this.container, id, name, options, 0, this.container.list.length * 30, this.onRuleChange.bind(this));
        this.rules[id] = rule;
    }
}

class Rule {
    constructor(scene, parent, id, name, options, x, y, onChange) {
        this.options = options;
        this.ruleName = name;
        this.ruleText = scene.add.text(0, 0, "", {font: "18px Tahoma"});
        this.ruleText.setPosition(x, y);
        this.optionIndex = 0;
        const that = this;
        this.ruleText.setInteractive();
        this.ruleText.on(Phaser.Input.Events.POINTER_DOWN, function () {
            that.cycleOptions();
            onChange(id, that.options[that.optionIndex]);
        });

        parent.add(this.ruleText);

        this.cycleOptions(0);
    }

    setValue(value) {
        const index = this.options.findIndex(option => option === value);
        if (index !== -1) this.cycleOptions(index);
    }

    cycleOptions(setIndex=null) {
        this.optionIndex = setIndex !== null ? setIndex : (this.optionIndex + 1) % this.options.length;
        this.ruleText.setText(`${this.ruleName}: ${this.options[this.optionIndex]}`);
    }
}

export default RulesSelector;