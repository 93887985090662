class TurnIndicator {
    constructor(scene) {
        this.scene = scene;
        this.sprite = scene.add.sprite(400, -200, 'turn-sun');
        this.sprite.setAlpha(0.5);
        this.scene.scale.on('resize', this.moveOnResize, this);
        this.opponent = null;
        this.rotationSpeed = 0.05;
    }

    update(delta) {
        this.sprite.angle += this.rotationSpeed * delta;
    }

    setRotationDirection(direction) {
        this.rotationSpeed = (direction === 'LEFT') ? -0.05 : 0.05;
    }

    clientTurn() {
        this.opponent = null;
        this.moveToPosition();
    }

    opponentTurn(opponent) {
        this.opponent = opponent;
        this.moveToPosition();
    }

    moveToPosition(tween=true) {
        if (this.opponent) this.opponent.autoPosition();

        const gameSize = { width: this.scene.scale.width, height: this.scene.scale.height };
        const position = this.opponent ? this.opponent.getPosition() : {x: gameSize.width - 100, y: gameSize.height - 90};
        if (tween) {
            this.scene.tweens.add({
                targets: [this.sprite],
                x: position.x,
                y: position.y,
                scaleX: this.opponent ? 1 : 0.5,
                scaleY: this.opponent ? 1 : 0.5,
                duration: 1000,
                ease: 'Quad.easeInOut'
            });
        } else {
            this.sprite.setPosition(position.x, position.y);
        }
    }

    moveOnResize() {
        this.moveToPosition(false);
    }

    destroy() {
        this.scene.scale.off('resize', this.moveOnResize, this);
    }
}

export default TurnIndicator;