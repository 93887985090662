import Phaser from 'phaser';
import Card from '../../Card';
import Hand from "../../Hand";
import Deck from "./Deck";
import Opponent from "./Opponent";
import Discard from "./Discard";
import TurnIndicator from "./TurnIndicator";
import Button from "../../../shared/Button";
import ColorPicker from "./ColorPicker";

const GAME_SPEED = 1.5;
export const BASE_CARD_DEPTH = 20;
export const DISCARD_BASE_CARD_DEPTH = 50;

class PlayTable extends Phaser.Scene {
    constructor(config) {
        super(config);
        this.eventQueue = [];
    }

    init(data) {
        this.client = data.client;
        this.clientName = data.clientName;
        this.leoMode = data.leoMode;
        this.tweens.setGlobalTimeScale(GAME_SPEED);
        this.time.timeScale = GAME_SPEED;
    }

    create() {
        this.createCardSprites();
        this.createColorPickerSprites();
        this.background = this.add.sprite(0, 0, 'green-background');
        this.background.setScale(1);
        this.background.setDepth(-20);
        this.background.setTint(0x00FF00);
        this.colorIndicator = this.add.circle(0, 0, 14, 0xFFFFFF);
        this.directionIndicator = this.add.sprite(0, 0, 'arrows');
        this.directionIndicator.setAlpha(0.3);
        this.directionIndicator.setScale(-1.5, 1.5);
        this.directionIndicator.setDepth(-5);
        this.rotationSpeed = 0.0075;
        this.mover = new MoveHandler(this, this.clientName, this.client, this.setTint.bind(this), this.setDirection.bind(this), this.backToLobby.bind(this), this.leoMode);
        this.scene.scene.scale.on('resize', this.onResize, this);
        this.onResize();
    }

    destroy() {
        this.scene.scene.scale.off('resize', this.onResize, this);
        this.mover.destroy();
    }

    backToLobby() {
        this.scene.launch("ChooseSeat", {client: this.client, clientName: this.clientName, leoMode: this.leoMode});
        this.scene.bringToTop("ConnectionStatus");
        this.destroy();
        this.scene.stop();
    }

    setTint(tint) {
        this.background.setTint(tint);
        this.colorIndicator.setFillStyle(tint);
    }

    setDirection(direction) {
        this.rotationSpeed = direction === 'RIGHT' ? 0.0075 : -0.0075;
        this.directionIndicator.setScale(direction === 'RIGHT' ? 1.5 : -1.5, 1.5);
    }

    onResize() {
        this.background.setPosition(this.scene.scene.scale.width / 2)
        const diameter = this.colorIndicator.radius * 2;
        this.colorIndicator.setPosition(this.scene.scene.scale.width - diameter, diameter);
        this.directionIndicator.setPosition(this.scene.scene.scale.width / 2, this.scene.scene.scale.height / 2);
    }

    update(time, delta) {
        this.checkEvents();

        if (this.eventQueue.length > 0 && this.mover.canAcceptEvent()) {
            this.mover.triggerEvent(this.eventQueue.shift());
        }
        this.mover.update(delta);
        this.directionIndicator.angle += this.rotationSpeed * delta;
    }

    checkEvents() {
        while (true) {
            const event = this.client.consumeEvent();
            if (!event) break;
            this.eventQueue.push(event);
        }
        if (this.eventQueue.length > 40) {
            const lastSyncIndexBackward = this.eventQueue.slice().reverse().findIndex(event => event.event === 'GAME_SYNC');
            if (lastSyncIndexBackward !== -1) {
                const count = this.eventQueue.length - 1;
                const lastSyncIndex = count - lastSyncIndexBackward;
                this.eventQueue = this.eventQueue.splice(lastSyncIndex);
            }
        }
    }

    createCardSprites() {
        const texture = this.textures.get('uno-deck');
        const cardWidth = 121.5;
        const cardHeight = 190;
        const textureWidth = 1215;
        const textureHeight = 1329;
        let cardIndex = 0;
        for (let y = 0; y < textureHeight; y += cardHeight) {
            for (let x = 0; x < textureWidth; x += cardWidth) {
                texture.add(String(cardIndex++), 0, x, y, cardWidth, cardHeight);
            }
        }

        texture.add("back", 0, (cardWidth * 6) + 0.5, cardHeight * 5, cardWidth, cardHeight);
        texture.add("back-glow", 0, (cardWidth * 7) + 0.5, cardHeight * 5, cardWidth, cardHeight);
        texture.add("green-outline", 0, (cardWidth * 8) + 1.5, (cardHeight * 5) - 1, cardWidth, cardHeight);
        texture.add("blank", 0, (cardWidth * 9) + 0.5, cardHeight * 6, cardWidth, cardHeight);
    }

    createColorPickerSprites() {
        const texture = this.textures.get('color-picker');
        texture.add('red', 0, 0, 0, 58, 92);
        texture.add('blue', 0, 57, 0, 57, 92);
        texture.add('yellow', 0, 0, 91, 58, 92);
        texture.add('green', 0, 57, 91, 57, 92);
    }
}

class MoveHandler {

    constructor(scene, clientName, client, setTint, setDirection, backToLobby, leoMode=false) {
        this.scene = scene;
        this.clientName = clientName;
        this.client = client;
        this.setTint = setTint;
        this.setDirection = setDirection;
        this.backToLobby = backToLobby;
        this.currentEvent = null;
        this.deck = new Deck(scene, this.tryDrawCard.bind(this));
        this.hand = new Hand(scene, this.onCardSelected.bind(this));
        this.leoMode = leoMode;
        this.turnIndicator = new TurnIndicator(scene);
        // { unpressed, hovered, pressed, onPressed, text, scale=1, onHover }
        const skipUnpressed = leoMode ? 'leo-unpressed' : 'round-unpressed';
        const skipPressed = leoMode ? 'leo-pressed' : 'round-pressed';
        this.skipButton = new Button(scene, {
            onPressed: this.trySkip.bind(this), unpressed:skipUnpressed, pressed:skipPressed, text:leoMode ? " " : "Skip", scale: 0.5,
        })
        this.skipButton.setOnResize(() => { this.skipButton.setPosition(this.scene.scale.width - 50, this.scene.scale.height - 230 ) });
        this.skipButton.setAlpha(0, false);
        this.endGameButton = new Button(scene, {
            onPressed: this.requestEndGame.bind(this), unpressed:'card-button', text:"Vote End", scale: 0.25, textSize: 15
        })
        this.endGameButton.setOnResize(() => { this.endGameButton.setPosition(60, 60 ) });
        this.endGameButton.setAlpha(0, false);
        this.colorPicker = new ColorPicker(scene, () => {});
        this.seats = {};
        this.firstSync = true;
        this.eventDelay = this.scene.time.addEvent({delay: 1});
        this.startActionDelay(1);

        this.discardPile = new Discard(this.scene);
    }

    // Called once per event
    triggerEvent(event) {
        if (event.event === 'GAME_SYNC') {
            this.sync(event);
        } else if (event.event === 'BACK_TO_LOBBY' || event.event === 'LOBBY_SYNC') {
            this.backToLobby();
        } else if (event.event === 'GAME_MOVE') {
            this.currentEvent = event;
            if (event.type === 'Set Turn') {
                this.setTurnPlayer(event.turnName);
                this.startEventDelay(750);
            } else if (event.type === 'Draw') {
                for (const name in event.hands) {
                    if (name === this.clientName) {
                        this.hand.setIncomingCards(event.hands[name].length);
                        this.hand.positionCards();
                    } else {
                        this.seats[name].setIncomingCards(event.hands[name].length);
                        this.seats[name].positionHand();
                    }
                }
            } else if (event.type === 'Deck Discard') {
                const card = this.deck.popCard();
                card.setValue(event.value);
                this.discardPile.addCard(card, true);
                this.startEventDelay(2000);
            } else if (event.type === 'Select Card') {
                this.hand.indicateValidCards(event.turnName === this.clientName ? event.legalCards : []);
                this.currentEvent = null;
            } else if (event.type === 'Play Card') {
                if (this.clientName === event.name) {
                    const card = this.hand.removeCard(event.cardIndex);
                    this.discardPile.addCard(card, true, { fromHand: true });
                    this.hand.positionCards(true);
                } else {
                    const card = this.seats[event.name].removeCard(event.cardIndex);
                    card.setValue(event.value);
                    this.discardPile.addCard(card, true, { fromOpponent: true });
                    this.seats[event.name].positionHand(true);
                }
            } else if (event.type === 'Can Skip') {
                this.setSkipStatus(event.name, event.canSkip);
                this.currentEvent = null;
            } else if (event.type === 'Emptied Hand') {
                this.startEventDelay(2000);
                if (this.endGameButton && event.position === 1) this.endGameButton.setAlpha(1, true);
                event.name === this.clientName ?
                    this.hand.showAwardWreath(event.position) :
                    this.seats[event.name].showAwardWreath(event.position);
            }
        }
    }

    update(delta) {
        this.hand.update();
        this.turnIndicator.update(delta);

        if (!this.currentEvent) return;

        const waitForTweensAndEventDelay = ['Set Turn', 'Deck Discard', 'Play Card', 'Draw One', 'Emptied Hand'];
        if (this.currentEvent.event === 'GAME_MOVE') {
            const type = this.currentEvent.type;
            if (type === 'Draw') {
                this.drawCards(this.currentEvent);
            } else if (waitForTweensAndEventDelay.includes(type)) {
                if (this.allTweensDone() && this.eventDelayComplete()) this.currentEvent = null;
            }
        }
    }

    drawCards(event) {
        // Draws one card at a time, short delay between each card
        if (this.actionDelay.getProgress() === 1) {
            let allCardsDrawn = true;
            for (const name in event.hands) {
                if (event.hands[name].length === 0) continue;
                allCardsDrawn = false;
                const drawnCard = this.deck.popCard();
                const cardValue = event.hands[name].shift();
                drawnCard.setValue(cardValue);
                if (name === this.clientName) {
                    this.hand.setIncomingCards(event.hands[name].length);
                    this.hand.addCard(drawnCard);
                } else {
                    this.seats[name].setIncomingCards(event.hands[name].length);
                    this.seats[name].addCard(drawnCard);
                }
                this.startActionDelay(300);
                break;
            }

            if (allCardsDrawn && this.allTweensDone()) {
                this.currentEvent = null;
            }
        }
    }

    tryDrawCard() {
        if (!this.currentEvent) this.client.emit({ event: "DRAW_CARD", name: this.clientName });
    }

    onCardSelected(cardIndex, wildcardColor) {
        if (!this.currentEvent) this.client.emit({ event: "PLAY_CARD", name: this.clientName, cardIndex, wildcardColor });
    }

    trySkip() {
        if (!this.currentEvent) this.client.emit({ event: "SKIP", name: this.clientName });
        if (this.leoMode) this.scene.sound.play("leo-button");
    }

    requestEndGame() {
        this.client.emit({ event: "VOTE_END", name: this.clientName });
        this.endGameButton.destroy();
        this.endGameButton = null;
    }

    setTurnPlayer(turnName) {
        turnName === this.clientName ?
            this.turnIndicator.clientTurn() :
            this.turnIndicator.opponentTurn( this.seats[turnName] );
    }

    startActionDelay(delay) {
        this.actionDelay = this.scene.time.addEvent({delay});
    }

    canAcceptEvent() {
        return !this.currentEvent;
    }

    allTweensDone() {
        return this.scene.tweens.getAllTweens().length === 0;
    }

    sync(event) {
        // Seats/players
        if (Object.keys(this.seats).length === 0) {
            let orderedSeats = event.seats.slice();
            if (orderedSeats.includes(this.clientName)) {
                const myNameIndex = orderedSeats.indexOf(this.clientName);
                orderedSeats = orderedSeats.slice(myNameIndex).concat(orderedSeats.slice(0, myNameIndex));
                orderedSeats.splice(orderedSeats.indexOf(this.clientName), 1);
            }
            orderedSeats.forEach((name, index) => {
                this.seats[name] = new Opponent(this.scene, orderedSeats.length, index, name);
            })
        }
        // Deck
        this.deck.setNumCards(event.deck, this.colorPicker);
        // Hands
        for (const name in event.hands) {
            const cards = event.hands[name].map(card => new Card(this.scene, 0, 0, 'uno-deck', card, this.colorPicker));
            if (this.clientName === name) {
                this.hand.setCards(cards);
            } else {
                this.seats[name].setCards(cards);
            }
        }
        // Turn
        if (event.turnName) {
            this.setTurnPlayer(event.turnName);
            this.hand.indicateValidCards(event.turnName === this.clientName ? event.legalCards : []);
        }
        this.turnIndicator.setRotationDirection(event.direction);
        this.setDirection(event.direction);
        this.setSkipStatus(event.turnName, event.turnPlayerCanSkip);
        // Discard - only syncs once, and only the top card
        if (this.firstSync && event.discard) {
            this.discardPile.addCard(new Card(this.scene, 0, 0, 'uno-deck', event.discard, this.colorPicker), false);
        }
        // Color
        let tint;
        switch (event.color) {
            case 'RED': tint = 0xFF0000; break;
            case 'BLUE': tint = 0x0000FF; break;
            case 'YELLOW': tint = 0xFFFF00; break;
            default: tint = 0x00FF00; break;
        }
        this.setTint(tint);
        // Game end button
        if (this.firstSync) {
            if (event.playersFinished > 0) this.endGameButton.setAlpha(1);
        }

        this.firstSync = false;
    }

    setSkipStatus(name, canSkip) {
        if (name === this.clientName) {
            this.skipButton.setAlpha(canSkip ? 1 : 0);
        }
    }

    startEventDelay(delay) {
        this.eventDelay = this.scene.time.addEvent({ delay });
    }

    eventDelayComplete() {
        return this.eventDelay.getProgress() >= 1;
    }

    destroy() {
        this.deck.destroy();
        this.hand.destroy();
        this.turnIndicator.destroy();
        this.skipButton.destroy();
        if (this.endGameButton) this.endGameButton.destroy();
        this.colorPicker.destroy();
        for (const name in this.seats) {
            this.seats[name].destroy();
        }
        this.discardPile.destroy();
    }
}

export default PlayTable;