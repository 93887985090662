import {io} from "socket.io-client";

class Client {
    constructor() {
        const thiz = this;
        const socket = io("https://uno-server-syyf.onrender.com");
        this.events = [];

        socket.on('connect', function() {
            console.log("CONNECTED");
            thiz.connected = true;
        });

        socket.on('disconnect', function() {
            console.log("DISCONNECTED");
            thiz.connected = false;
        });

        socket.on('event', function(payload) {
            if (process.env.NODE_ENV === 'development') console.log("Received: ", payload);
            thiz.events.push(payload);
        })

        this.socket = socket;
    }

    emit(payload) {
        console.log("Emitting: ", payload);
        this.socket.emit('event', payload);
    }

    isConnected() {
        return this.connected;
    }

    consumeEvent() {
        if (this.events.length === 0) return null;
        return this.events.shift();
    }

    addEvents(events=[]) {
        this.events = this.events.concat(events);
    }
}

export default Client;