import OpponentPositions from "./OpponentPositions";
import {BASE_CARD_DEPTH} from "./PlayTable";
import PlacementTextMap from "./PlacementTextMap";

class Opponent {
    constructor(scene, numOpponents, opponentIndex, name="Lameguest") {
        this.size = { x: 200, y: 200 };
        this.scene = scene;
        this.positionInfo = OpponentPositions[String(numOpponents)][opponentIndex];
        this.graphics = scene.add.graphics();
        this.graphics.fillStyle(0, 0.2);
        this.graphics.lineStyle(3, 0x7F0000, 1);
        this.graphics.fillCircle(0, 0, this.size.y/2);
        this.graphics.strokeCircle(0, 0, this.size.y/2);
        this.winWreath = scene.add.sprite(0, 0, 'flower-circle');
        this.winWreath.setVisible(false);

        this.scene.scale.on('resize', this.autoPosition, this);
        this.nameText = scene.add.text(0, 0, name, {font: "24px Tahoma"});
        this.nameText.setOrigin(0.5, 0.5);
        this.placementText = scene.add.text(0, 0, "", {font: "24px Tahoma"});
        this.placementText.setVisible(false);
        this.placementText.setOrigin(0.5, 0.5);

        this.hand = new OpponentHand(scene);

        this.autoPosition();

        this.setIncomingCards = this.hand.setIncomingCards.bind(this.hand);
        this.removeCard = this.hand.removeCard.bind(this.hand);
    }

    addCard(card) {
        this.hand.addCard(card);
        this.positionOneCard(this.hand.cards.length - 1);
    }

    setCards(cards) {
        this.hand.setCards(cards);
        this.positionHand(false);
    }

    showAwardWreath(placement) {
        this.placementText.setVisible(true);
        this.placementText.setText(PlacementTextMap[placement]);
        this.winWreath.setTexture(placement === 1 ? 'flower-circle' : 'flower-circle-2');
        this.winWreath.setScale(0);
        this.winWreath.setVisible(true);
        this.scene.tweens.add({
            targets: [this.winWreath],
            scaleX: 1,
            scaleY: 1,
            duration: 2000,
            ease: 'Bounce.easeOut'
        });
        this.autoPosition();
    }

    getPosition() {
        return { x: this.graphics.x, y: this.graphics.y };
    }

    autoPosition() {
        const gameSize = { width: this.scene.scale.width, height: this.scene.scale.height };
        if (this.positionInfo.left) {
            this.graphics.setX(gameSize.width * (this.positionInfo.left/100));
        } else {
            this.graphics.setX(gameSize.width - (gameSize.width * (this.positionInfo.right/100)));
        }

        if (this.positionInfo.top) {
            this.graphics.setY(gameSize.height * (this.positionInfo.top/100));
        } else {
            this.graphics.setY(gameSize.height - (gameSize.height * (this.positionInfo.bottom/100)));
        }

        this.nameText.setPosition(this.graphics.x, 50 + this.graphics.y - this.size.y / 2);
        if (this.winWreath.visible) {
            this.placementText.setPosition(this.graphics.x, this.graphics.y);
            this.winWreath.setPosition(this.graphics.x, this.graphics.y);
        }
        this.positionHand(false);
    }

    positionHand(tween=true) {
        this.hand.positionCards(this.graphics.x, this.graphics.y, this.size.x, this.size.y, tween);
    }

    positionOneCard(index) {
        this.hand.positionOneCard(this.graphics.x, this.graphics.y, this.size.x, this.size.y, index);
    }

    destroy() {
        this.graphics.destroy();
        this.nameText.destroy();
        this.scene.scale.off('resize', this.autoPosition, this);
    }
}

class OpponentHand {
    constructor(scene) {
        this.scene = scene;
        this.cards = [];
        this.numIncomingCards = 0;
    }

    addCard(card) {
        this.cards.push(card);
        card.faceUp(false, false);
    }

    removeCard(index) {
        return this.cards.splice(index, 1)[0];
    }

    setCards(cards) {
        this.cards.forEach(card => { card.destroy(); });
        this.cards = cards;
    }

    setIncomingCards(numIncomingCards) {
        this.numIncomingCards = numIncomingCards;
    }

    positionCards(parentX, parentY, parentWidth, parentHeight, tween=true) {
        if (this.cards.length === 0) return;
        const { cardGap, start } = this.calculateCardPositionInfo(parentX, parentY, parentWidth, parentHeight);
        this.cards.forEach((card, i) => {
            this.doCardPositioning(i, start, cardGap, tween);
        });
        this.orderCards();
    }

    positionOneCard(parentX, parentY, parentWidth, parentHeight, index) {
        const { cardGap, start } = this.calculateCardPositionInfo(parentX, parentY, parentWidth, parentHeight);
        this.doCardPositioning(index, start, cardGap, true);
    }

    calculateCardPositionInfo(parentX, parentY, parentWidth, parentHeight) {
        const totalCards = this.cards.length + this.numIncomingCards;
        const cardGap = Math.max(Math.min(100 / totalCards, 125), 4);
        const handWidth = (totalCards-1) * (cardGap);
        const start = { x: parentX - handWidth/2, y: parentY + parentHeight/2 - 20 };
        return { cardGap, start };
    }

    doCardPositioning(index, start, cardGap, tween) {
        const x = start.x + (index * cardGap);
        const y = start.y;
        const scaleX = 0.3;
        const scaleY = 0.3;
        const card = this.cards[index];
        if (tween) {
            card.tween({x, y, ease: 'Cubic.easeInOut', duration: 1800, scaleX, scaleY, angle: 0 });
            this.scene.time.delayedCall(750, () => { card.setDepth(BASE_CARD_DEPTH + index); });
        } else {
            card.setPosition(x, y);
            card.setScale(scaleX);
        }
        card.faceUp(false, false);
    }

    orderCards() {
        this.cards.forEach((card, index) => { card.setDepth(BASE_CARD_DEPTH + index) });
    }
}

export default Opponent;