import MakeSpriteButton from "../shared/MakeSpriteButton";
import CardMap from "./CardMap";

class Card {
    constructor(scene, x, y, texture, value, colorPicker) {
        this.scene = scene;
        this.sprite = scene.add.sprite(x, y, texture, CardMap[value] ? CardMap[value] : 'blank');
        this.sprite.scaleX = 0.5;
        this.sprite.scaleY = 0.5;
        this.value = value;
        this.colorPicker = colorPicker;
        this.frontFrame = this.sprite.frame;
        this.backFrame = 'back';
        this.validSprite = scene.add.sprite(x, y, texture, 'green-outline');
        this.validSprite.setScale(0.75);
        this.validSprite.setVisible(false);

        this.state = {
            facingUp: true
        };

        MakeSpriteButton(this.sprite, { onPressed: this.onSelected.bind(this), onHover: this.onHover.bind(this) });

        this.setAngle = this.sprite.setAngle.bind(this.sprite);
        this.setScale = this.sprite.setScale.bind(this.sprite);
        this.setAlpha = this.sprite.setAlpha.bind(this.sprite);
    }

    setPosition(x, y) {
        this.sprite.setPosition(x, y);
        this.validSprite.setPosition(x, y);
    }

    setDepth(depth) {
        this.sprite.setDepth(depth);
        this.validSprite.setDepth(depth);
    }

    tween(config) {
        this.scene.tweens.add({
            targets: this.validSprite.visible ? [this.sprite, this.validSprite] : [this.sprite],
            ...config,
        });
    }

    setValidIndication(isValid) {
        this.validSprite.setVisible(isValid);
        if (isValid) {
            this.validSprite.setPosition(this.sprite.x, this.sprite.y);
            this.validSprite.setDepth(this.sprite.depth);
        }
    }

    setValue(value) {
        this.value = value;
        this.frontFrame = CardMap[value];
        if (this.state.facingUp) this.sprite.setFrame(this.frontFrame);
    }

    setSelectable(selectable, highlightOnHover=false) {
        if (selectable) this.sprite.setInteractive();
        else {
            this.sprite.disableInteractive();
            this.onHover(false);
        }
        this.highlightOnHover = highlightOnHover;
    }

    faceUp(up, animate=true, delay=0) {
        if (up === this.state.facingUp) return;
        this.state.facingUp = up;

        const flip = () => { this.sprite.setFrame(up ? this.frontFrame : this.backFrame) }

        if (animate) {
            this.tween({
                scaleX: 0,
                duration: 350,
                ease: 'Quad.easeIn',
                yoyo: true,
                onYoyo: flip,
                delay
            })
        } else {
            flip();
        }
    }

    setSelectionCallback(callback) {
        this.selectionCallback = callback;
    }

    onSelected() {
        if (this.value && this.value.includes('WILD')) {
            this.colorPicker.setColorSelectedCallback(this.onColorSelected.bind(this));
            this.colorPicker.show();
        } else {
            if (this.selectionCallback) this.selectionCallback();
        }
    }

    onColorSelected(color) {
        if (this.selectionCallback) this.selectionCallback(color);
    }

    onHover(hover) {
        this.highlightOnHover ? this.highlightCard(hover) : this.displayEnlargedSprite(hover);
    }

    highlightCard(hover) {
        this.sprite.setFrame(hover ? 'back-glow' : 'back');
    }

    displayEnlargedSprite(hover) {
        const showHoverSprite = hover && !this.scene.tweens.isTweening(this.sprite);
        if (!this.hoverSprite) {
            this.hoverSprite = this.scene.add.sprite(0, 0, 'uno-deck');
            this.hoverSprite.setScale(0.9);
        }
        if (showHoverSprite) {
            this.hoverSprite.frame = this.sprite.frame;
            this.hoverSprite.setDepth(this.sprite.depth + 19);
            this.validSprite.setDepth(this.sprite.depth + 20);
            this.validSprite.setScale(0.9);
            this.hoverSprite.setPosition(this.sprite.x, this.sprite.y);
            this.hoverSprite.setAngle(this.sprite.angle);
            this.hoverSprite.setAlpha(this.sprite.alpha);
        } else {
            this.validSprite.setScale(0.75);
            this.validSprite.setDepth(this.sprite.depth);
        }
        this.hoverSprite.setVisible(showHoverSprite);
    }

    destroy() {
        this.sprite.destroy();
        this.validSprite.destroy();
        if (this.hoverSprite) this.hoverSprite.destroy();
    }
}

export default Card;