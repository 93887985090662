import MakeSpriteButton from "../../../shared/MakeSpriteButton";
import Phaser from "phaser";

class ColorPicker {
    constructor(scene) {
        this.scene = scene;
        this.position = {x: 0, y: 0};
        this.onColorPicked = () => {};
        const that = this;
        function makeColor(color) {
            this[color] = scene.add.sprite(0, 0, 'color-picker', color);
            this[color].setDepth(1001);
            MakeSpriteButton(this[color],
                { onPressed: () => that.onColorSelected(color), onHover: (hover) => that.onColorHovered(hover, color) });
            this[color].setInteractive();
            this[color].setVisible(false);
            return this[color];
        }
        this.colors = [];
        this.colors.push(makeColor.call(this, 'red'));
        this.colors.push(makeColor.call(this, 'blue'));
        this.colors.push(makeColor.call(this, 'yellow'));
        this.colors.push(makeColor.call(this, 'green'));
        this.textureOffsets = {
            'wedge-red': { x: -36, y: -56 },
            'wedge-blue': { x: 35, y: -56 },
            'wedge-yellow': { x: -36, y: 56 },
            'wedge-green': { x: 35, y: 56 },
        }
        this.hoverSprite = scene.add.sprite(0, 0);
        this.hoverSprite.setVisible(false);
        this.hoverSprite.setDepth(1002);
        this.overlay = scene.add.sprite(0, 0, 'black-pixel').setInteractive();
        this.overlay.setDepth(1000);
        this.overlay.setOrigin(0, 0);
        this.overlay.setAlpha(0.35);
        this.overlay.setVisible(false);
        this.overlay.on(Phaser.Input.Events.POINTER_DOWN, this.hide.bind(this));
        this.autoPosition();
        this.scene.scale.on('resize', this.autoPosition, this);
    }

    setColorSelectedCallback(func) {
        this.onColorPicked = func;
    }

    onColorSelected(color) {
        this.onColorPicked(color.toUpperCase());
        this.hide();
    }

    show() {
        this.colors.forEach(color => color.setVisible(true));
        this.overlay.setVisible(true);
    }

    hide() {
        this.colors.forEach(color => color.setVisible(false));
        this.hoverSprite.setVisible(false);
        this.overlay.setVisible(false);
    }

    onColorHovered(hover, color) {
        const key = 'wedge-'+color;
        if (!hover && this.hoverSprite.texture.key === key) {
            this.hoverSprite.setVisible(false);
        } else {
            this.hoverSprite.setTexture('wedge-' + color);
            this.hoverSprite.setVisible(true);
        }
        this.autoPosition();
    }

    autoPosition() {
        const gameSize = { width: this.scene.scale.width, height: this.scene.scale.height };
        this.position = { x: gameSize.width / 2, y: gameSize.height - 272 };

        this.red.setPosition(this.position.x - 29, this.position.y - 46);
        this.blue.setPosition(this.position.x + 28, this.position.y - 46);
        this.yellow.setPosition(this.position.x - 29, this.position.y + 46);
        this.green.setPosition(this.position.x + 28, this.position.y + 46);

        if (this.hoverSprite.visible && this.hoverSprite.texture)
            this.hoverSprite.setPosition(this.position.x + this.textureOffsets[this.hoverSprite.texture.key].x,
                this.position.y + this.textureOffsets[this.hoverSprite.texture.key].y);

        this.overlay.setDisplaySize(gameSize.width, gameSize.height);
    }

    destroy() {
        this.scene.scale.off('resize', this.autoPosition, this);
        this.red.destroy();
        this.blue.destroy();
        this.yellow.destroy();
        this.blue.destroy();
        this.hoverSprite.destroy();
    }
}

export default ColorPicker;