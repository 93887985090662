import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const JoinBox = styled.div`
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    
    & > button {
        width: 100px;
        height: 50px;
    }
`;

function Join({ setName, setLeoMode }) {
    const [value, setValue] = React.useState("");
    const [leoMode, setLeoValue] = React.useState(false);

    React.useEffect(() => {
        const savedName = window.localStorage.getItem("UnoName");
        if (savedName) setValue(savedName);
    }, [])

    return <Container>
        <JoinBox>
            <label>What's your name?</label>
            <input type="text" value={value} onChange={e => setValue(e.target.value)} />
            <button onClick={() => {
                setName(value);
                setLeoMode(leoMode);
                window.localStorage.setItem("UnoName", value);
            }}>JOIN</button>
            <div><label>Leo mode: </label><input type="checkbox" value={leoMode} onChange={e => setLeoValue(e.target.checked)} /></div>
        </JoinBox>
    </Container>
}

export default Join;