import './App.css';
import React from 'react';
import GameBox from "./uno_game/GameBox";
import Join from "./setup/Join";

function App() {
    const [name, setName] = React.useState();
    const [leoMode, setLeoMode] = React.useState();

    if (name) {
        return <div className="App">
            <GameBox clientName={name} leoMode={leoMode} />
        </div>;
    } else {
        return <Join setName={setName} setLeoMode={setLeoMode} />;
    }
}

export default App;
