const PlacementTextMap = {
    1: "WINNER!",
    2: "2nd Place",
    3: "3rd Place",
    4: "4th Place",
    5: "5th Place",
    6: "6th Place",
    7: "7th Place",
    8: "8th Place",
    9: "9th Place",
    10: "10th Place",
}

export default PlacementTextMap;