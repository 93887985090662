import Phaser from "phaser";

class Button {
    constructor(scene, { unpressed, hovered, pressed, onPressed, text, textSize=26, scale=1, onHover }={}) {
        this.sprite = scene.add.sprite(0, 0, unpressed).setInteractive();
        this.sprite.setScale(scale);
        this.buttonHeld = false;
        this.over = false;
        this.scene = scene;

        const that = this;
        this.sprite.on(Phaser.Input.Events.POINTER_OVER, function () {
            if (hovered) that.sprite.setTexture(hovered);
            if (onHover) onHover(true);
            that.over = true;
        });
        this.sprite.on(Phaser.Input.Events.POINTER_OUT, function () {
            if (hovered) that.sprite.setTexture(hovered);
            if (onHover) onHover(false);
            that.over = false;
            that.buttonHeld = false;
        });
        this.sprite.on(Phaser.Input.Events.POINTER_DOWN, function () {
            if (pressed) that.sprite.setTexture(pressed);
            if (that.over) that.buttonHeld = true;
        });
        this.sprite.on(Phaser.Input.Events.POINTER_UP, function () {
            if (unpressed) that.sprite.setTexture(unpressed);
            if (that.over && that.buttonHeld) onPressed();
            that.buttonHeld = false;
        });

        if (text) {
            this.text = scene.add.text(0, 0, text, {font: `${textSize}px Tahoma`});
            this.text.setOrigin(0.5, 0.5);
        }

        this.setSize = this.sprite.setSize.bind(this.sprite);
    }

    setOnResize(func) {
        this.onResize = func;
        this.scene.scale.on('resize', this.onResize, this);
        this.onResize();
    }

    setAlpha(val, tween=true) {
        if (tween) {
            this.scene.tweens.add({
                targets: [this.sprite],
                alpha: val,
                duration: 500
            });
            this.scene.tweens.add({
                targets: [this.text],
                alpha: val,
                duration: 500
            });
        } else {
            this.sprite.setAlpha(val);
            this.text.setAlpha(val);
        }
    }

    setPosition(x, y) {
        this.sprite.setPosition(x, y);
        if (this.text) this.text.setPosition(x, y);
    }

    destroy() {
        this.sprite.destroy();
        this.text.destroy();
        if (this.onResize) this.scene.scale.off('resize', this.onResize, this);
    }

}

export default Button;